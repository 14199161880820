<template>
  <div class="wrapper" v-show="graphic.side === editorSide">
    <div class="placer" draggable="false" ref="placer">
      <div class="bounds" ref="bounds"></div>
      <div class="scaler" ref="scaler">
        <img
          class="icon"
          src="@/assets/icons/scale.svg"
          draggable="false"
          ondragstart="return false"
        />
      </div>
      <div class="rotater" ref="rotater">
        <img
          class="icon"
          src="@/assets/icons/rotate.svg"
          draggable="false"
          ondragstart="return false"
        />
      </div>
      <div class="closer" @click="remove">
        <img
          class="icon"
          src="@/assets/icons/close.svg"
          draggable="false"
          ondragstart="return false"
        />
      </div>
      <button class="flipper" @click="flipSide">Flip</button>
    </div>
    <img
      v-if="imageType === 'image'"
      :src="imageData"
      class="image"
      ref="image"
      draggable="false"
      ondragstart="return false"
    />
    <canvas
      v-else-if="imageType === 'pdf'"
      class="image"
      ref="image"
      draggable="false"
      ondragstart="return false"
    />
  </div>
</template>

<style lang="less" scoped>
.wrapper {
  position: absolute;
  user-select: none !important;
}

.placer {
  position: absolute;
  width: auto;
  height: auto;
  transform-origin: center center;

  > * {
    z-index: 3;
  }
}
.bounds {
  position: relative;
  outline: 2px dashed rgba(0, 0, 50, 0.5);
  outline-offset: 1px;
  pointer-events: none;
}
.scaler {
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: translate(100%, 100%);
  color: blue;
  cursor: nesw-resize;
  touch-action: none;
}
.rotater {
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(85%, -85%);
  cursor: pointer;
  touch-action: none;
}
.icon {
  max-width: 20px;
  user-select: none;
  user-drag: none;
  pointer-events: none;
  margin: 3px;

  @media (pointer: coarse) {
    max-width: 30px;
  }
}
.closer {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-88%, -86%);
  color: #7000a0;
}
button.flipper {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-110%, -50%);
  border: none;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 13px;
  color: #7000a0;
  background: lightgray;
}

.image {
  position: absolute;
  transform-origin: center center;
  cursor: move;
  touch-action: none;
}
</style>

<script>
const pdfjsPromise = () => import("pdfjs-dist/webpack");
let pdfjs;

const HammerPromise = () => import("@egjs/hammerjs");

let hammer;

function getRenderedSize(contains, cWidth, cHeight, width, height, pos) {
  var oRatio = width / height,
    cRatio = cWidth / cHeight;
  return function() {
    if (contains ? oRatio > cRatio : oRatio < cRatio) {
      this.width = cWidth;
      this.height = cWidth / oRatio;
    } else {
      this.width = cHeight * oRatio;
      this.height = cHeight;
    }
    this.left = (cWidth - this.width) * (pos / 100);
    this.right = this.width + this.left;
    return this;
  }.call({});
}

function getImgSizeInfo(img) {
  var pos = window
    .getComputedStyle(img)
    .getPropertyValue("object-position")
    .split(" ");
  return getRenderedSize(
    true,
    img.width,
    img.height,
    img.naturalWidth,
    img.naturalHeight,
    parseInt(pos[0])
  );
}

export default {
  data() {
    return {
      imageData: null, // only used by browser-supported formats
      imageType: "",

      dragStart: null,
      minScale: 0.01,

      offsetMod: [0, 0],
      scaleMod: 1,

      translation: [0, 0],
      scale: 0, // used for widget scaling
      relativeScale: 1.0, // used for multitouch scaling
      rotation: 0,
      multitouchRotationStart: 0.0,
      multitouchRotation: 0.0, // separated to prevent regular rotation handler from firing

      isMultitouchActive: false // used to prevent translation
    };
  },

  props: {
    graphic: Object,
    editorSide: String
  },

  computed: {
    oldTranslation: {
      get() {
        return this.graphic.transform.translation;
      },
      set(value) {
        this.$store.commit("transformGraphic", {
          target: this.graphic,
          transform: {
            translation: value
          }
        });
      }
    },
    oldRotation: {
      get() {
        return this.graphic.transform.rotation;
      },
      set(value) {
        this.$store.commit("transformGraphic", {
          target: this.graphic,
          transform: {
            rotation: value
          }
        });
      }
    },
    oldScale: {
      get() {
        return this.graphic.transform.scale;
      },
      set(value) {
        this.$store.commit("transformGraphic", {
          target: this.graphic,
          transform: {
            scale: value
          }
        });
      }
    }
  },

  methods: {
    updateTransform() {
      const translateX = `calc(${(this.oldTranslation[0] +
        this.translation[0]) *
        this.scaleMod +
        this.offsetMod[0]}px - 50%)`;
      const translateY = `calc(${(this.oldTranslation[1] +
        this.translation[1]) *
        this.scaleMod +
        this.offsetMod[1]}px - 50%)`;
      this.$refs.image.style.transform = `
        translate(${translateX}, ${translateY})
        scale(${Math.max(
          (this.oldScale * this.relativeScale +
            this.scale * (1 / this.scaleMod)) *
            this.scaleMod,
          this.minScale
        )})
        rotate(${this.oldRotation + this.rotation + this.multitouchRotation}rad)
      `;

      this.$refs.placer.style.left =
        this.$refs.image.getBoundingClientRect().left + "px";
      this.$refs.placer.style.top =
        this.$refs.image.getBoundingClientRect().top + "px";
      this.$refs.bounds.style.width =
        this.$refs.image.getBoundingClientRect().width + "px";
      this.$refs.bounds.style.height =
        this.$refs.image.getBoundingClientRect().height + "px";
    },

    // determines transform modifications based on editor size
    // keeps all graphics scaled relative to visual size of the apparel.
    updateDefaultTransform() {
      this.offsetMod = [
        this.$el.parentElement.getBoundingClientRect().width / 2,
        this.$el.parentElement.getBoundingClientRect().height / 2
      ];

      var newScaleMod = 1;
      const imageMaxDimension = Math.max(
        this.$refs.image.naturalWidth,
        this.$refs.image.naturalHeight
      );
      if (imageMaxDimension > 1000) {
        newScaleMod *= 1000 / imageMaxDimension;
      }
      newScaleMod *=
        getImgSizeInfo(document.querySelector("#template")).width /
        document.querySelector("#template").naturalWidth;

      this.scaleMod = newScaleMod;

      this.updateTransform();
    },

    flipSide() {
      this.$store.commit("flipGraphicSide", this.graphic);
      this.$emit("flipSide");
    },

    remove() {
      this.$store.commit("removeGraphic", this.graphic);
    },

    // event handlers that are attached to parent or window (so they can be removed)
    translationHandler(event) {
      if (this.isMultitouchActive === false) {
        // mouseMove
        this.translation = [
          (event.pageX - this.dragStart[0]) * (1 / this.scaleMod),
          (event.pageY - this.dragStart[1]) * (1 / this.scaleMod)
        ];

        this.updateTransform();
      }
    },

    rotationHandler(event) {
      const center = [
        (this.$refs.bounds.getBoundingClientRect().left +
          this.$refs.bounds.getBoundingClientRect().right) /
          2,
        (this.$refs.bounds.getBoundingClientRect().top +
          this.$refs.bounds.getBoundingClientRect().bottom) /
          2
      ];

      this.rotation =
        Math.atan((event.clientY - center[1]) / (event.clientX - center[0])) -
        this.dragStart;

      this.updateTransform();
    },

    scaleHandler(event) {
      const baseWidth =
        this.$refs.image.naturalWidth !== undefined
          ? this.$refs.image.naturalWidth
          : this.$refs.image.offsetWidth;
      const baseHeight =
        this.$refs.image.naturalHeight !== undefined
          ? this.$refs.image.naturalHeight
          : this.$refs.image.offsetHeight;
      const scaleX = (event.clientX - this.dragStart[0]) / baseWidth;
      const scaleY = (event.clientY - this.dragStart[1]) / baseHeight;

      this.scale =
        this.oldScale + scaleX < this.oldScale + scaleY
          ? scaleX * 2
          : scaleY * 2;

      this.updateTransform();
    },

    translationMouseupHandler(event) {
      this.oldTranslation[0] += this.translation[0];
      this.oldTranslation[1] += this.translation[1];
      this.translation = [0, 0];

      this.$parent.$el.removeEventListener(
        "pointermove",
        this.translationHandler
      );

      this.$emit("drag", false);
    },

    rotationMouseupHandler(event) {
      this.oldRotation += this.rotation;
      this.rotation = 0;
      // this.$refs.rotater.style.transform = "scale(1)";
      // this.$refs.rotater.querySelector(".icon").style.transform = "scale(1)";
      this.$parent.$el.removeEventListener("pointermove", this.rotationHandler);
    },

    scaleMouseupHandler(event) {
      // this.$refs.scaler.style.transform = "scale(1)";
      // this.$refs.scaler.querySelector(".icon").style.transform = "scale(1)";
      this.$parent.$el.removeEventListener("pointermove", this.scaleHandler);
      this.oldScale = Math.max(
        this.oldScale + this.scale * (1 / this.scaleMod),
        this.minScale
      );
      this.scale = 0;
    },

    resizeHandler() {
      this.updateDefaultTransform();
    }
  },

  async mounted() {
    // load image
    const reader = new FileReader();

    if (
      // render image from file, for browser-supported formats
      this.graphic.file.type.startsWith("image/")
    ) {
      this.imageType = "image";
      reader.addEventListener("loadend", result => {
        this.imageData = result.target.result;

        this.$refs.image.addEventListener(
          "load",
          () => {
            this.updateDefaultTransform();
          },
          { once: true }
        );

        // handle translation (image must exist first)
        this.$refs.image.addEventListener("pointerdown", event => {
          this.dragStart = [event.pageX, event.pageY];
          this.$parent.$el.addEventListener(
            "pointermove",
            this.translationHandler
          );

          this.$emit("drag", true);
        });
        window.addEventListener("pointerup", this.translationMouseupHandler);
      });

      reader.readAsDataURL(this.graphic.file);
    } else if (
      // render image from file, for PDF, .ai, and other derived formats
      this.graphic.file.type !== "application/pdf" ||
      this.graphic.file.type !== "application/postscript"
    ) {
      this.imageType = "pdf";
      await reader.addEventListener("loadend", async result => {
        const typedarray = new Uint8Array(result.target.result);
        if (pdfjs === undefined) {
          pdfjs = await pdfjsPromise();
        }
        pdfjs.getDocument(typedarray).promise.then(pdf => {
          pdf.getPage(1).then(page => {
            const context = this.$refs.image.getContext("2d");

            // determine correct scale and set canvas dimensions
            const pageMaxSize = Math.max(page.view[2], page.view[3]);
            const scale = pageMaxSize > 1000 ? 1000 / pageMaxSize : 1;
            this.$refs.image.width = page.view[2] * scale;
            this.$refs.image.height = page.view[3] * scale;

            page.render({
              canvasContext: context,
              background: "rgba(0,0,0,0)",
              viewport: page.getViewport({ scale: scale })
            });
            this.updateDefaultTransform();

            // handle translation (image must exist first)
            this.$refs.image.addEventListener("pointerdown", event => {
              this.dragStart = [event.pageX, event.pageY];
              this.$parent.$el.addEventListener(
                "pointermove",
                this.translationHandler
              );

              this.$emit("drag", true);
            });
            window.addEventListener(
              "pointerup",
              this.translationMouseupHandler
            );
          });
        });
      });

      reader.readAsArrayBuffer(this.graphic.file);
    }

    // handle rotation
    this.$refs.rotater.addEventListener("pointerdown", event => {
      const center = [
        (this.$refs.bounds.getBoundingClientRect().left +
          this.$refs.bounds.getBoundingClientRect().right) /
          2,
        (this.$refs.bounds.getBoundingClientRect().top +
          this.$refs.bounds.getBoundingClientRect().bottom) /
          2
      ];

      // this.$refs.rotater.style.transform = "scale(100)";
      // this.$refs.rotater.querySelector(".icon").style.transform = "scale(0.01)";

      this.dragStart = this.dragStart = Math.atan(
        (event.clientY - center[1]) / (event.clientX - center[0])
      );

      this.$parent.$el.addEventListener("pointermove", this.rotationHandler);
    });
    window.addEventListener("pointerup", this.rotationMouseupHandler);

    // handle scale
    this.$refs.scaler.addEventListener("pointerdown", event => {
      if (this.minScale === 0) {
        if (this.graphic.data !== undefined) {
          this.minScale = Math.max(
            25 / this.$refs.image.naturalWidth,
            25 / this.$refs.image.naturalHeight
          );
        } else {
          this.minScale = Math.max(
            25 / this.$refs.image.offsetWidth,
            25 / this.$refs.image.offsetHeight
          );
        }
      }

      // this.$refs.scaler.style.transform = "scale(100)";
      // this.$refs.scaler.querySelector(".icon").style.transform = "scale(0.01)";

      this.dragStart = [event.pageX, event.pageY];
      this.$parent.$el.addEventListener("pointermove", this.scaleHandler);
    });
    window.addEventListener("pointerup", this.scaleMouseupHandler);

    // handle window resize
    window.addEventListener("resize", this.resizeHandler);

    // add multi-touch handler (Hammer.js)
    this.$nextTick(async () => {
      const Hammer = await HammerPromise();
      hammer = new Hammer.Manager(this.$refs.image);
      var pinch = new Hammer.Pinch({ threshold: 0.1 });
      var rotate = new Hammer.Rotate({ threshold: 0 });
      pinch.recognizeWith(rotate);
      hammer.add([pinch, rotate]);
      hammer.on("pinch", event => {
        this.isMultitouchActive = true;
        this.relativeScale = event.scale - 0.1;

        this.updateTransform();
      });
      hammer.on("pinchend", event => {
        this.oldScale = Math.max(
          this.oldScale * this.relativeScale,
          this.minScale
        );
        this.relativeScale = 1.0;
        this.updateTransform();
        this.isMultitouchActive = false;
      });

      hammer.on("rotatestart", event => {
        this.multitouchRotationStart = event.rotation;
      });
      hammer.on("rotate", event => {
        this.isMultitouchActive = true;
        this.multitouchRotation =
          (event.rotation - this.multitouchRotationStart) * (Math.PI / 180);
        this.updateTransform();
      });
      hammer.on("rotateend", event => {
        this.oldRotation += this.multitouchRotation;
        this.multitouchRotation = 0.0;
        this.multitouchRotationStart = 0.0;
        this.updateTransform();
        this.isMultitouchActive = false;
      });
    });
  },

  beforeDestroy() {
    // remove event handlers from parent
    for (const parentHandler of [
      this.translationHandler,
      this.rotationHandler,
      this.scaleHandler
    ]) {
      this.$parent.$el.removeEventListener("pointermove", parentHandler);
    }

    // remove event handlers from window
    for (const windowHandler of [
      this.translationMouseupHandler,
      this.rotationMouseupHandler,
      this.scaleMouseupHandler
    ]) {
      window.removeEventListener("pointerup", windowHandler);
    }
    window.removeEventListener("resize", this.resizeHandler);

    hammer.destroy();
  }
};
</script>
